import React, { useContext, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import UnitedKingdomFlag from "../assets/images/United-Kingdom 1.svg";
import UAFlag from "../assets/images/ua.svg";
import MobileLogo from "../assets/images/swaponix.svg";
import ArrowYellow from "../assets/images/arrowyellow.svg";
import useModal from "../hooks/useModal";
import LoginModal from "./modals/ClientAuthModal";


const Header = () => {
  const location = useLocation();
  const [mobileToggle, setMobileToggle] = useState(false);
  const [isShowingModal, toggleModal] = useModal();
    return (
      <>
    <header>
      <div className="header_centerblock">
        <div className="nav">
          <div className="logo">
            <NavLink to="/">
              <img src={Logo} alt="logo"/>
            </NavLink>
          </div>
          <ul>
            <li>
              <NavLink to="about">About</NavLink>
            </li>
            <li>
              <NavLink to="howtouse">How to use</NavLink>
            </li>
            <li>
              <NavLink to="contacts">Contacts</NavLink>
            </li>
            {/* <li className="hover_li"> */}
            <li>
              {/* <li> */}
              Partners <img src={ArrowYellow} alt="" />
            </li>
            <li>
              {/* <li> */}
              <span className="text-gold-gradient">Public nodes</span>
              <div className="soon">Soon</div>
            </li>
            <div className="lan">
              <img className="United-Kingdom" src={UnitedKingdomFlag} alt="ENG" />
              EN
              {/* <div className="lan_change"><img className="" style={{width: "25px"}} src={UAFlag} alt="UA"/>UA</div> */}
            </div>
            <LoginModal show={isShowingModal} onClose={toggleModal} />
            <div role="button" onClick={toggleModal} className="login-block">Sign in</div>
          </ul>
        </div>
        <div className="nav_mobile">
          <div className="logo">
            <NavLink to="/">
              <img src={MobileLogo} alt="" />
            </NavLink>
          </div>
          <div className="burger">
            <nav role="navigation">
              <div id="menuToggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu">
                  <NavLink to="about">
                    <li>About</li>
                  </NavLink>
                  <NavLink to="howtouse">
                    <li>How to use</li>
                  </NavLink>
                  <NavLink to="contacts">
                    <li>Contacts</li>
                  </NavLink>
                  {/* <li onClick={() => {setMobileToggle(!mobileToggle)}}>
                    Partners <img src={ArrowYellow} alt="" />
                  </li>
                  {mobileToggle ?  
                  <ul>
                      <li>
                        <Link className="partners-mobile" to="https://swaponix.io:5000/swagger/" target="_blank" rel="noopener noreferrer">
                          API Docs
                        </Link>
                      </li>
                    </ul>
                    :
                    null
                } */}
                  {/* <div className="lan">
                    <img className="United-Kingdom" src={UnitedKingdomFlag} alt="ENG" />
                    EN
                    <img src={ArrowYellow} alt="" />
                    <div className="lan_change">UK</div>
                  </div> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>

    </>
  );
};

export default Header;
