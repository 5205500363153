import React from "react";
import arrow_left from "../../assets/images/arrowadminleft.svg";
import arrow_right from "../../assets/images/arrowadminright.svg";
import PairCell from "./TableCells/PairCell";
import TimeCell from "./TableCells/TimeCell";

const PaginatedTable = ({ items, currentPage, totalPages, setCurrentPage, setAlertIsOpen, headers }) => {
  const tableCellDict = (key, props) => {
    console.log({props})
    const dict = {
      'pair': <PairCell {...props} />,
      'time': props && <TimeCell time={props} />,
      'start_time': props && <TimeCell time={props} />,
      'created_at': props && <TimeCell time={props} />
    }
    return dict[key]
  }
  const paginatorRender = () => {
    const buttons = [];
    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${currentPage === i ? "active_page" : ""}`} onClick={() => setCurrentPage(i)}>
            {i}
          </div>
        );
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${currentPage === i ? "active_page" : ""}`} onClick={() => setCurrentPage(i)}>
            {i}
          </div>
        );
      }
      buttons.push(
        <div key={0} className={`page-button margin`}>
          ...
        </div>
      );
      for (let i = totalPages - 4; i <= totalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${currentPage === i ? "active_page" : ""}`} onClick={() => setCurrentPage(i)}>
            {i}
          </div>
        );
      }
    }

    return buttons;
  };

  console.log(items)

  const renderEmptyRows = (rows) => {
    const res_rows = [];
    for (let i = rows; i < 10; i++) {
      res_rows.push(
        <tr className="content_table">
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
        </tr>
      );
    }
    return res_rows;
  };

  return (
    <>
      <div className="content_block row">
        <table className={`table ${items.length < 1 && 'empty'}`}>
          <tr className="th_names">
            {headers.map(header => (
              <th key={header.key} style={{ width: header.width, maxWidth: header.width, minWidth: header.width}} className={header.className}>{header.label}</th>
            ))}
          </tr>

          {items.length ? items
            ?.slice()
            ?.reverse()
            ?.map((item) => {
              return (
                <tr className="content_table">
                  {headers.map(header => {
                    let value;
                    if (header.key === 'pair') {
                      value = { item, fixed: item.fixed };
                    } else {
                      value = header.valueFormatter ? header.valueFormatter(item[header.key]) : item[header.key]
                    }

                    return tableCellDict(header.key, value) || <td key={header.key} style={{ width: header.width, maxWidth: header.width, minWidth: header.width}} className="padding">{value}</td>

                  })}
                </tr>
              )
            }) : <tr className="empty-table-placeholder"><td colSpan={headers.length}>Nothing found</td></tr>}
          {renderEmptyRows(items?.results?.length)}
        </table>
        <div className="slidepages" style={{ userSelect: "none" }}>
          <div
            className="arrowleft margin"
            onClick={() => {
              items?.next && setCurrentPage((prev) => prev - 1);
            }}
          >
            <img src={arrow_left} alt="" />
          </div>
          {paginatorRender()}
          <div
            className="arrowright margin"
            onClick={() => {
              items?.previous && setCurrentPage((prev) => prev + 1);
            }}
          >
            <img src={arrow_right} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginatedTable;