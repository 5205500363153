import React, { useState, useEffect, useContext, useRef } from "react";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Hist from "../../assets/images/Polygon 1.svg";

import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { host } from "../../http";

const AdminLayout = observer(() => {
  const location = useLocation();
  const { admin } = useContext(Context);
  const navigate = useNavigate();
  const [options, setOptions] = useState(null);
  const [fixed, setFixed] = useState(null);
  const [float, setFloat] = useState(null);
  const [custSearch, setCustSearch] = useState("");
  const timeoutSearch = useRef(null);
  

  useEffect(() => {
    let link = document.querySelector("link");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/favicon-32x32.png';
    if (!admin.isAuth) {
      navigate('/admin/login');
    }
  }, []);


 
  useEffect(() => {
    if (timeoutSearch.current) {
      clearTimeout(timeoutSearch.current);
    }

    timeoutSearch.current = setTimeout(() => {
      host.get(`/coin/api/coin/`,{params: {search: custSearch}}) // use turnary to change URL and add querry
      .then(response => {
        let data = response.data.results
        setOptions(data)
      })
      .catch(error => {
        console.error('Error:', error.response?.data || error.message);
      });
    }, 400);

    return () => {
      if (timeoutSearch.current) {
        clearTimeout(timeoutSearch.current);
      }
    };
  }, [custSearch]);




  const toggleList = (id) => {
    var list = document.getElementById(id);
    if (list.style.display === "none") {
      list.style.display = "block";
    } else {
      list.style.display = "none";
    }
  };

  if (!admin.isAuth) {
    return <Outlet />;
  }
  return (
    <main className="admin">
      <div id="content">
        <div className="flex_block">
          <div className="navigation">
            <Link to="/admin">
              <img src={Logo} alt="" />
            </Link>
            <ul>
              <li onClick={() => toggleList("list1")} style={{ fontWeight: 700 }}>
                Main <img src={Hist} alt="" />
              </li>
              <ul id="list1" style={{ display: "block", paddingLeft: "15px", fontWeight: 500 }}>
                <li className={location.pathname === "/admin/swaps" ? "active-item" : ""}>
                  <Link to="/admin/swaps">Swaps</Link>
                </li>
                <li className={location.pathname === "/admin/orders" ? "active-item" : ""}>
                  <Link to="/admin/orders">Orders</Link>
                </li>
                <li className={location.pathname === "/admin/deposit" ? "active-item" : ""}>
                  <Link to="/admin/deposit">Deposit</Link>
                </li>
                <li className={location.pathname === "/admin/withdrawal" ? "active-item" : ""}>
                  <Link to="/admin/withdrawal">Withdrawal</Link>
                </li>
                <li className={location.pathname === "/admin/partners" ? "active-item" : ""}>
                  <Link to="/admin/partners">Partners Payouts</Link>
                </li>
              </ul>
              <li onClick={() => toggleList("list2")} style={{ fontWeight: 700 }}>
                Setting <img src={Hist} alt="" />
              </li>
              <ul id="list2" style={{ display: "block", paddingLeft: "15px", fontWeight: 500 }}>
                <li className={location.pathname === "/admin/control" ? "active-item" : ""}>
                  <Link to="/admin/control">Control</Link>
                </li>
                <li className={location.pathname === "/admin/tokens" ? "active-item" : ""}>
                  <Link to="/admin/tokens">Tokens</Link>
                </li>
              </ul>
            </ul>
          </div>
          <Outlet />
        </div>
      </div>
    </main>
  );
});

export default AdminLayout;
