import React, { useState, useEffect, useRef, useContext } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { UserService } from "../../http/services/UserService";
import { useNavigate } from "react-router-dom";
import { Context } from "../..";

export default function AuthModal({ show, onClose }) {
  const [isLogin, setIsLogin] = useState(true);
  const { client } = useContext(Context);
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [error, setError] = useState(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show, onClose]);

  if (!show) return null;

  const toggleMode = () => setIsLogin(!isLogin);

  const saveCredits = (credits) => {
    console.log(credits);
    // TODO: DELETE
    localStorage.setItem("token", credits.access);
    localStorage.setItem("refreshToken", credits.refresh);
    document.cookie = `token=${credits.access}; path=/;`;
    document.cookie = `refreshToken=${credits.refresh}; path=/;`;
    client.setIsAuth(true);
  };

  const onSubmit = (data) => {
    if (isLogin) {
      UserService.login(data)
        .then((res) => {
          console.log(res);
          saveCredits(res);
          navigate("/client/dashboard");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            setError("Inccorect password or mail");
          }
        });
    } else {
      UserService.register({
        ...data,
        referral: localStorage.getItem("referralCode"),
      }).then((res) => {
        saveCredits(res);
        navigate("/client/dashboard");
      });
    }
  };

  return createPortal(
    <div className="modal-bg">
      <div className="login-modal" ref={modalRef}>
        <div className="login-modal__header">
          <Logo />
          <h2 className="login-modal__title">
            {isLogin ? "Sign in to your account" : "Create an account"}
          </h2>
        </div>

        <div className="login-modal__form-container">
          <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <div className="login-form__field">
              <label htmlFor="email" className="login-form__label">
                Email address
              </label>
              <div className="login-form__input-wrapper">
                <input
                  id="username"
                  {...register("username", {
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                  type="email"
                  autoComplete="email"
                  className={`login-form__input ${
                    errors.username ? "validation_failed" : ""
                  }`}
                />
              </div>
              {errors.username && (
                <span className="login-form__error">
                  {errors.username.message}
                </span>
              )}
            </div>

            <div className="login-form__field">
              <div className="login-form__password-header">
                <label htmlFor="password" className="login-form__label">
                  Password
                </label>
              </div>
              <div className="login-form__input-wrapper">
                <input
                  id="password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                  type="password"
                  autoComplete={isLogin ? "current-password" : "new-password"}
                  className={`login-form__input ${
                    errors.password ? "validation_failed" : ""
                  }`}
                />
              </div>
              {errors.password && (
                <span className="login-form__error">
                  {errors.password.message}
                </span>
              )}
            </div>

            {!isLogin && (
              <div className="login-form__field">
                <label htmlFor="confirmPassword" className="login-form__label">
                  Confirm Password
                </label>
                <div className="login-form__input-wrapper">
                  <input
                    id="confirmPassword"
                    {...register("confirmPassword", {
                      required: "Please confirm your password",
                      validate: (val) => {
                        if (watch("password") !== val) {
                          return "Your passwords do not match";
                        }
                      },
                    })}
                    type="password"
                    autoComplete="new-password"
                    className={`login-form__input ${
                      errors.confirmPassword ? "validation_failed" : ""
                    }`}
                  />
                </div>
                {errors.confirmPassword && (
                  <span className="login-form__error">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
            )}

            {error && <div className="error-msg">{error}</div>}

            <div className="login-form__submit">
              <button type="submit" className="login-form__button">
                {isLogin ? "Sign in" : "Sign up"}
              </button>
            </div>
          </form>

          <p className="login-modal__signup-text">
            {isLogin ? "Not a member?" : "Already have an account?"}{" "}
            <a href="#" className="login-modal__link" onClick={toggleMode}>
              {isLogin ? "Sign up" : "Sign in"}
            </a>
          </p>
        </div>
      </div>
    </div>,
    document.body
  );
}
