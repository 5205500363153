import { adminHost, authHost } from "../index";

const BASE_PATH = process.env.REACT_APP_API_URL;

export const AdminService = {
    getPartnerPayouts: async ({ page, created_at }) => {
        return await adminHost.get(`${BASE_PATH}/partner/api/withdrawal/from_admin/`, {
            params: {
                page,
                created_at,
                page_size: 20
            }
        }).then(res => {
            console.log(res.data);
            return res.data;
        })
    },
}