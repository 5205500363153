"use client";
import React, { useState, useEffect, useContext, useRef } from "react";
import { host } from "../../http";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import {ReactComponent as LogoutIcon} from '../../assets/icons/nav-icons/logout.svg'
import Logo from "../../assets/images/client_logo.svg";
import LogoString from "../../assets/images/logo_string.svg";
import { Context } from "../../index";
import { ReactComponent as DashboardIcon } from "../../assets/icons/nav-icons/dashboard.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/nav-icons/history.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/nav-icons/settings.svg";

import { observer } from "mobx-react-lite";
import useModal from "../../hooks/useModal";
import ChangePassword from "../modals/ChangePassword";

const ClientLayout = observer(() => {
  const { client } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowingModal, toggleModal] = useModal();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    document.cookie = "token=; path=/;";
    document.cookie = "refreshToken=; path=/;";
    navigate("/");
  };

  useEffect(() => {
    let link = document.querySelector("link");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "/favicon-32x32.png";

    if (!client.isAuth) {
      navigate("/");
    }
  }, []);

  const handleScreenChange = (screen) => {
    client.setActiveScreen(screen);
  };
  if (!client.isAuth) {
    return <Outlet />;
  }
  return (
    <main className="client">
      <div id="content">
        <div className="flex_block">
          <div className="navigation">
            <div className="logo-container">
            <Link to="/client/dashboard">
              <img src={Logo} alt="" />
            </Link>
            <img src={LogoString} alt="" />
            </div>
            <ul>
              <ul
                id="list1"
                style={{
                  display: "block",
                  paddingLeft: "15px",
                  fontWeight: 700,
                }}
              >
                <li
                  className={
                    location.pathname === "/client/dashboard"
                      ? "active-item"
                      : ""
                  }
                >
                  <Link to="/client/dashboard">
                    <DashboardIcon />
                    Dashboard
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/client/history" ? "active-item" : ""
                  }
                >
                  <Link to="/client/history">
                    {" "}
                    <HistoryIcon />
                    History
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/client/settings"
                      ? "active-item"
                      : ""
                  }
                >
                  <Link to="/client/settings">
                    {" "}
                    <SettingsIcon />
                    Settings
                  </Link>
                </li>
              </ul>
            </ul>
            <div className="navigation__bottom">

            <button onClick={toggleModal} className="header-button">
                  Change Password
                </button>
            <button onClick={handleLogout} className="header-button"><LogoutIcon />Log out</button>
            </div>
          </div>
          <div className="client-page-wrapper">
            <div>
              <ChangePassword show={isShowingModal} onClose={toggleModal} />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  );
});

export default ClientLayout;
